<template>
  <v-row class="ma-0">
    <v-col>
      <v-card elevation="5" max-width="570" class="mx-auto pa-15" v-if="card">
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/recover.svg" alt="lock" />
          <v-row>
            <v-col cols="9" class="mx-auto">
              <div class="text-h5 font-weight-bold mt-7 text-break text-center">Recover your password</div>
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">
                We will send you a link to reset your password. Enter the email address you use to access Coton.
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form @submit.prevent="submit" class="px-15" ref="otp" lazy-validation>
            <v-text-field
              v-model="mail"
              class="mx-1"
              :error-messages="emailErrors"
              placeholder="Email"
              filled
              outlined
              dense
              required
              color="input"
            ></v-text-field>
            <div class="text-center mt-2">
              <v-btn type="submit" width="300" height="48" color="primary">Confirm</v-btn>
            </div>
          </v-form>
        </v-card-text>
        <div class="mt-7">
          <v-btn to="/login" text class="text-capitalize secondary--text opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </div>
      </v-card>
      <v-card elevation="5" max-width="570" class="mx-auto pa-15" v-else>
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/recover.svg" alt="lock" />
          <v-row>
            <v-col cols="9" class="mx-auto">
              <div class="text-h5 font-weight-bold mt-7 text-break text-center">Recover your password</div>
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">We have sent pssword reset link to {{ mail }}</div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="flex-column px-15 mt-5">
          <v-btn to="/login" text class="text-capitalize secondary--text opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    mail: { required, email },
  },
  data() {
    return {
      card: true,
      mail: null,
      status: null,
      error: null,
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.mail.$dirty) {
        return errors;
      }
      !this.$v.mail.email && errors.push('Provided email address is not valid');
      !this.$v.mail.required && errors.push('Please enter the email address you have used during the sign-up');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'email__not_found' && errors.push('Sorry, but the user with the provided email is not found');
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          email: this.mail,
        };
        await this.$store
          .dispatch('reqResPass', data)
          .then((response) => {
            this.status = response.status;
            sessionStorage.setItem('jwt_token', response.data.access_token);
            this.card = !this.card;
          })
          .catch((e) => {
            this.status = e.response.status;
            this.error = e.response.data.error[0];
            if (this.error == 'account__suspended') {
              this.$router.push('/account-blocked');
            }
          });
      }
    },
  },
};
</script>
